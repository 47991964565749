import React, { useState, useEffect } from 'react';
import { Transition } from "@headlessui/react";
import 'animate.css';
import { faCheck, faGraduationCap, faHouse, faRectangleList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const NavBar = () => {
    const [isOpen, setIsOpen] = useState(false);
    
    const [theme, setTheme] = useState(null);

	useEffect(() => {
		if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
			setTheme('dark');
		} else {
			setTheme('light');
		}
	}, []);

	const handleThemeSwitch = () => {
		setTheme(theme === 'dark' ? 'light' : 'dark');
	};

	useEffect(() => {
		if (theme === 'dark') {
			document.documentElement.classList.add('dark');
		} else {
			document.documentElement.classList.remove('dark');
		}
	}, [theme]);

    return (
        <>
        <div className=" m-auto w-11/12 backdrop-blur-xl lg:bg-transparent rounded-xl ">
        <nav className="">
            <div className="mx-auto px-4 sm:px-6 lg:px-8 w-full md:w-full xl:w-4/5 rounded-xl ">
            <div className="flex items-center h-16">
                <div className="flex w-full justify-between items-center">
                <a href="https://vicen.me " className="flex-shrink-0 w-1/5 md:text-center">
                    <h2 className=" text-lg font-bold text-black">vicen.<span className=" bg-sky-400 p-0.5 rounded-md text-white">DEV</span></h2>
                </a>
                <div className="hidden md:hidden lg:block w-3/5">
                    <div className=" flex items-center space-x-4 justify-center">
                            <a id='btn-contact' href="https://vicen.me" className=" hover:scale-105 transition font-semibold hover:bg-sky-400 text-black hover:text-white px-3 py-2 rounded-md text-sm "> Inicio</a>
                            <a id='btn-contact' href="https://vicen.me/cv " className="hover:scale-105 transition font-semibold text-black hover:bg-sky-400 hover:text-white px-3 py-2 rounded-md text-sm "> Habilidades</a>
                            <a id='btn-contact' href="https://vicen.me/cv"  className="hover:scale-105 transition font-semibold text-black hover:bg-sky-400 hover:text-white px-3 py-2 rounded-md text-sm " > Trayectoria</a>
                            <a id='btn-contact' href="https://vicen.me/projects/" className="hover:scale-105 transition font-semibold text-black hover:bg-sky-400 hover:text-white px-3 py-2 rounded-md text-sm "> Proyectos</a>
                    </div>
                </div>
                    <div className="flex items-center w-1/5 gap-3 justify-center">
                        <a href="mailto:vicen@vicen.me"><button id="btn-contact" className="block w-full px-4 py-2.5 text-sm font-bold text-sky-400 bg-sky-400/10 color-btn rounded-lg sm:w-auto active:text-opacity-75 hover:bg-transparent hover:scale-105 transition focus:outline-none">
                        Hablemos</button>
                        </a>
                        <button type="button" onClick={handleThemeSwitch} className="hidden md:block  py-1.5 px-3 text-xl rounded-lg hover:scale-105 transition">
                            {theme === 'dark' 
                            ? 
                            <svg viewBox="0 0 24 24" fill="none" className="w-6 h-6"><path fillRule="evenodd" clipRule="evenodd" d="M17.715 15.15A6.5 6.5 0 0 1 9 6.035C6.106 6.922 4 9.645 4 12.867c0 3.94 3.153 7.136 7.042 7.136 3.101 0 5.734-2.032 6.673-4.853Z" className="fill-sky-400/20"></path><path d="m17.715 15.15.95.316a1 1 0 0 0-1.445-1.185l.495.869ZM9 6.035l.846.534a1 1 0 0 0-1.14-1.49L9 6.035Zm8.221 8.246a5.47 5.47 0 0 1-2.72.718v2a7.47 7.47 0 0 0 3.71-.98l-.99-1.738Zm-2.72.718A5.5 5.5 0 0 1 9 9.5H7a7.5 7.5 0 0 0 7.5 7.5v-2ZM9 9.5c0-1.079.31-2.082.845-2.93L8.153 5.5A7.47 7.47 0 0 0 7 9.5h2Zm-4 3.368C5 10.089 6.815 7.75 9.292 6.99L8.706 5.08C5.397 6.094 3 9.201 3 12.867h2Zm6.042 6.136C7.718 19.003 5 16.268 5 12.867H3c0 4.48 3.588 8.136 8.042 8.136v-2Zm5.725-4.17c-.81 2.433-3.074 4.17-5.725 4.17v2c3.552 0 6.553-2.327 7.622-5.537l-1.897-.632Z" className="fill-sky-500"></path><path fillRule="evenodd" clipRule="evenodd" d="M17 3a1 1 0 0 1 1 1 2 2 0 0 0 2 2 1 1 0 1 1 0 2 2 2 0 0 0-2 2 1 1 0 1 1-2 0 2 2 0 0 0-2-2 1 1 0 1 1 0-2 2 2 0 0 0 2-2 1 1 0 0 1 1-1Z" className="fill-sky-500"></path>
                            </svg>
                            :     
                            <svg viewBox="0 0 24 24" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="w-6 h-6"><path d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" class="fill-sky-400/20 stroke-sky-500"></path><path d="M12 4v1M17.66 6.344l-.828.828M20.005 12.004h-1M17.66 17.664l-.828-.828M12 20.01V19M6.34 17.664l.835-.836M3.995 12.004h1.01M6 6l.835.836" class="stroke-sky-500"></path></svg>
                            }
                        </button>
                    </div>
                </div>
                <div className="-mr-2 flex lg:hidden">
                <button type="button" onClick={handleThemeSwitch} className="md:hidden ml-8 py-1.5 px-3 text-xl rounded-lg hover:scale-105 transition">
                            {theme === 'dark' 
                            ? 
                            <svg viewBox="0 0 24 24" fill="none" className="w-6 h-6"><path fillRule="evenodd" clipRule="evenodd" d="M17.715 15.15A6.5 6.5 0 0 1 9 6.035C6.106 6.922 4 9.645 4 12.867c0 3.94 3.153 7.136 7.042 7.136 3.101 0 5.734-2.032 6.673-4.853Z" className="fill-sky-400/20"></path><path d="m17.715 15.15.95.316a1 1 0 0 0-1.445-1.185l.495.869ZM9 6.035l.846.534a1 1 0 0 0-1.14-1.49L9 6.035Zm8.221 8.246a5.47 5.47 0 0 1-2.72.718v2a7.47 7.47 0 0 0 3.71-.98l-.99-1.738Zm-2.72.718A5.5 5.5 0 0 1 9 9.5H7a7.5 7.5 0 0 0 7.5 7.5v-2ZM9 9.5c0-1.079.31-2.082.845-2.93L8.153 5.5A7.47 7.47 0 0 0 7 9.5h2Zm-4 3.368C5 10.089 6.815 7.75 9.292 6.99L8.706 5.08C5.397 6.094 3 9.201 3 12.867h2Zm6.042 6.136C7.718 19.003 5 16.268 5 12.867H3c0 4.48 3.588 8.136 8.042 8.136v-2Zm5.725-4.17c-.81 2.433-3.074 4.17-5.725 4.17v2c3.552 0 6.553-2.327 7.622-5.537l-1.897-.632Z" className="fill-sky-500"></path><path fillRule="evenodd" clipRule="evenodd" d="M17 3a1 1 0 0 1 1 1 2 2 0 0 0 2 2 1 1 0 1 1 0 2 2 2 0 0 0-2 2 1 1 0 1 1-2 0 2 2 0 0 0-2-2 1 1 0 1 1 0-2 2 2 0 0 0 2-2 1 1 0 0 1 1-1Z" className="fill-sky-500"></path>
                            </svg>
                            :     
                            <svg viewBox="0 0 24 24" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="w-6 h-6"><path d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" class="fill-sky-400/20 stroke-sky-500"></path><path d="M12 4v1M17.66 6.344l-.828.828M20.005 12.004h-1M17.66 17.664l-.828-.828M12 20.01V19M6.34 17.664l.835-.836M3.995 12.004h1.01M6 6l.835.836" class="stroke-sky-500"></path></svg>
                            }
                </button>
                <button
                    onClick={() => setIsOpen(!isOpen)}
                    type="button"
                    className="bg-sky-400 inline-flex transition ml-1 md:ml-6 sm:ml-0 items-center justify-center p-2 rounded-md text-white hover:text-white hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                    aria-controls="mobile-menu"
                    aria-expanded="false"
                >
                    <span className="sr-only">Open main menu</span>
                    {!isOpen ? (
                    <svg
                        className="block h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                    >
                        <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h16"
                        />
                    </svg>
                    ) : (
                    <svg
                        className="block h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                    >
                        <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                    )}
                </button>
                </div>
            </div>
            </div>

            <Transition
            show={isOpen}
            enter="transition ease-out duration-100 transform"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-75 transform"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
            >
            {(ref) => (
                <div className="lg:hidden pt-2 rounded-b-xl" id="mobile-menu">
                <div ref={ref} className="px-4 pt-3 pb-3 sm:px-3 backdrop-blur-md bg-white/60 dark:bg-slate-800/70 border-2 rounded-2xl mx-3">
                <a href="https://vicen.me" target="_blank" rel="noopener noreferrer"
                        className="dark:text-white hover:bg-sky-400 text-black hover:text-white block px-3 py-2 rounded-md text-base font-semibold"
                        >
                        <FontAwesomeIcon icon={faHouse} /> Inicio
                        </a>
                        <a href="https://vicen.me/#tech" target="_blank" rel="noopener noreferrer"
                        className="text-black dark:text-white hover:bg-gradient-to-r from-blue-500 via-blue-600 to-blue-800 hover:text-white block px-3 py-2 rounded-md text-base font-semibold"
                        >
                        <FontAwesomeIcon icon={faCheck} /> Habilidades
                        </a>
                        <a href="https://vicen.me/trayectoria" target="_blank" rel="noopener noreferrer"
                        className="text-black dark:text-white hover:bg-gradient-to-r from-blue-500 via-blue-600 to-blue-800 hover:text-white block px-3 py-2 rounded-md text-base font-semibold"
                        >
                        <FontAwesomeIcon icon={faGraduationCap} /> Trayectoria
                        </a>
                        <a href="https://vicen.me/proyectos" target="_blank" rel="noopener noreferrer"
                        className="text-black dark:text-white hover:bg-gradient-to-r from-blue-500 via-blue-600 to-blue-800 hover:text-white block px-3 py-2 rounded-md text-base font-semibold"
                        >
                        <FontAwesomeIcon icon={faRectangleList} /> Proyectos
                        </a>
                </div>
                </div>
            )}
            </Transition>
        </nav>
        </div>
        </>
    );
}
